<template>
    <client-page>
        <mypage-layout title="회원정보 수정">
            <v-tabs-items :value="tab" touchless>
                <myinfo-tab-verify @next="tab = 'modify'" />
                <v-tab-item value="modify">
                    <v-form v-model="isValid">
                        <div class="txt txt--xs text-right pb-16px pb-md-20px"><span class="red--text">* </span>필수입력 항목입니다.</div>
                        <vertical-form-table v-model="form" v-bind="{ items }">
                            <template slot="아이디">
                                {{ form?.username }}
                            </template>
                            <template slot="비밀번호 확인">
                                <v-password-confirm-field :password="form.password" placeholder="동일한 비밀번호를 재입력하세요." isModify />
                            </template>
                            <template slot="주소">
                                <v-address-field v-model="form" outlined persistent-placeholder :btnAttrs="{ large: true, color: 'primary' }" />
                            </template>
                            <template slot="추천인코드">
                                {{ form?.code_promoter?.code }}
                            </template>
                        </vertical-form-table>

                        <div class="btn-wrap btn-wrap--lg">
                            <v-row justify="center" class="row--sm">
                                <v-col cols="6" sm="auto">
                                    <v-btn v-bind="{ ...btn_tertiary, ...$attrs }" class="v-size--xx-large w-100 min-w-sm-200px" @click="tab = 'verify'">취소</v-btn>
                                </v-col>
                                <v-col cols="6" sm="auto">
                                    <v-btn v-bind="{ ...btn_primary, ...$attrs, disabled }" class="v-size--xx-large w-100 min-w-sm-200px" @click="save">수정</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </v-tab-item>
            </v-tabs-items>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { initUser, rules } from "@/assets/variables";
import { btn_primary, btn_tertiary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import VAddressField from "@/components/plugins/vuetify/v-address-field.vue";
import MyinfoTabVerify from "@/components/client/mypage/myinfo/myinfo-tab-verify.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import VPasswordConfirmField from "@/components/plugins/vuetify/v-password-confirm-field.vue";

export default {
    components: {
        ClientPage,
        MypageLayout,
        VAddressField,
        MyinfoTabVerify,
        VerticalFormTable,
        VPasswordConfirmField,
    },
    data: () => ({
        btn_primary,
        btn_tertiary,

        form: initUser(),

        tab: "verify",
        isValid: false,
    }),
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        isUserKakao() {
            return this.form.username == `k${this.form.kakao}`;
        },
        disabled() {
            return !this.isValid;
        },
        items() {
            let items = [
                {
                    term: "아이디",
                },
                {
                    key: "password",
                    term: "비밀번호",
                    type: "password",
                    placeholder: "비밀번호 변경 시 새로운 비밀번호를 입력하세요",
                    rules: rules.passwordModify,
                    hides: this.isUserKakao,
                },
                {
                    key: "password",
                    term: "비밀번호 확인",
                    placeholder: "동일한 비밀번호를 재입력하세요.",
                    hides: this.isUserKakao,
                },
                {
                    key: "name",
                    term: "이름",
                    type: "text",
                    required: true,
                    placeholder: "이름을 입력하세요.",
                    rules: rules.name,
                },
                {
                    key: "email",
                    term: "이메일",
                    type: "email",
                    required: true,
                    placeholder: "이메일을 입력하세요.",
                    rules: rules.email,
                },
                {
                    key: "phone",
                    term: "연락처",
                    type: "phone",
                    required: true,
                },
                // {
                //     term: "주소",
                //     type: "address",
                //     required: true,
                // },
                // {
                //     term: "추천인코드",
                // },
            ].map((item) => ({ ...item, outlined: true }));

            return items.filter(({ hides }) => !(hides ?? false));
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (!this.accessToken) {
                    this.$router.push("/login");
                    return;
                }

                this.form = initUser((await api.v1.me.get())?.user);
                if (this.isUserKakao) this.tab = "modify";
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        async save() {
            try {
                let { password, ...form } = this.form;
                if (password) form.password = CryptoAES.encrypt(password);

                const { user } = await api.v1.me.put(form);
                this.form = user;

                alert("수정되었습니다");
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },
    },
};
</script>
