<template>
    <client-page>
        <mypage-layout title="쿠폰 내역">
            <mypage-coupons-nav />

            <page-section class="page-section--sm py-0 fill-height">
                <template #containerImmersive>
                    <v-tabs-items :value="tab" class="fill-height">
                        <v-tab-item :value="null" class="fill-height">
                            <mypage-coupons-list />
                        </v-tab-item>
                        <v-tab-item value="download" class="fill-height">
                            <mypage-coupons-down />
                        </v-tab-item>
                    </v-tabs-items>
                </template>
            </page-section>
        </mypage-layout>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import MypageCouponsNav from "@/components/client/mypage/coupons/mypage-coupons-nav.vue";
import MypageCouponsList from "@/components/client/mypage/coupons/mypage-coupons-list.vue";
import MypageCouponsDown from "@/components/client/mypage/coupons/mypage-coupons-down.vue";

export default {
    components: {
        ClientPage,
        MypageLayout,
        PageSection,
        MypageCouponsNav,
        MypageCouponsList,
        MypageCouponsDown,
    },
    props: {
        tab: { type: String, default: null },
    },
};
</script>
