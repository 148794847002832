<template>
    <client-page>
        <mypage-layout title="회원탈퇴">
            <v-form>
                <div v-if="leave === 'main'">
                    <div class="txt line-height-15">
                        <p>
                            회원탈퇴란, 사이트에 대한 이용해지를 의미합니다. <br />
                            발송하는 광고성 이메일의 경우 회원탈퇴 요청 접수 후 24시간 이내에 발송이 중지됩니다.<br />
                            다만, 드물게 데이터 처리사정상 일부 지연될 수 있으니 혹 회원탈퇴 요청 후 48시간이 지난 후에 광고성 이메일/SMS를 접수하시는 경우 당사에 연락하여 주시기 바랍니다.
                        </p>
                        <p>
                            <b>회원탈퇴 시 유의사항</b><br />
                            회원탈퇴 시 보유하고 계신 쿠폰 및 적립금은 즉시 소멸되며 동일한 아이디로 재가입 하더라도 복원되지 않습니다.
                        </p>
                        <p>
                            <b>탈퇴회원 회원정보 보전기간</b><br />
                            - 회원탈퇴가 완료되더라도 판/구매자의 권익을 보호하기 위해 다음과 같이 회원정보가 일정기간 보존됨을 알려드립니다.<br />
                            - 관계 법령에 의거하여 보존이 필요한 경우에 한하여 보존됩니다.<br />
                            1) 계약 및 청약철회 등에 관한기록 : 5년<br />
                            2) 대금결제 및 재화등의 공급에 관한 기록 : 5년<br />
                            3) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년<br />
                            4) 부정이용 등에 관한 기록 : 1년
                        </p>
                    </div>
                </div>

                <div v-if="leave === 'sub'">
                    <!-- 개발확인 : 바인딩확인부탁드립니다. -->
                    <vertical-form-table v-bind="{ items }">
                        <template slot="탈퇴사유"></template>
                        <template slot="남기실 말씀"></template>
                    </vertical-form-table>
                </div>

                <div class="btn-wrap btn-wrap--lg">
                    <v-row justify="center" class="row--sm">
                        <v-col v-if="leave === 'sub'" cols="6" sm="auto">
                            <v-btn outlined color="grey" class="v-size--xx-large w-100 min-w-sm-200px" @click="leave = 'main'">취소</v-btn>
                        </v-col>
                        <v-col v-if="leave === 'sub'" cols="6" sm="auto">
                            <v-btn color="grey darken-4" class="v-size--xx-large w-100 min-w-sm-200px" @click="withdraw">탈퇴신청</v-btn>
                        </v-col>
                        <v-col v-if="leave === 'main'" cols="6" sm="auto">
                            <v-btn color="grey darken-4" class="v-size--xx-large w-100 min-w-sm-200px" @click="leave = 'sub'">탈퇴신청</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </mypage-layout>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

const items = [
    {
        key: "withdrawalReason",
        term: "탈퇴사유",
        type: "text",
        required: false,
        placeholder: "탈퇴사유를 입력하세요",
    },
    {
        key: "withdrawalMessage",
        term: "남기실 말씀",
        type: "textarea",
        required: false,
        placeholder: "남기실 말씀을 입력하세요",
    },
].map((item) => ({ ...item, outlined: true }));

export default {
    components: {
        ClientPage,
        MypageLayout,
        VerticalFormTable,
    },
    data: function() {
        return {
            items,

            leave: "main",

            withdrawalReason: null,
            withdrawalMessage: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            if (!this.accessToken) {
                alert("로그인 이후 이용가능합니다");
                this.$router.push("/login");
                return;
            }
        },
        async withdraw() {
            try {
                await this.postWithdraw({
                    withdrawalReason: this.withdrawalReason,
                    withdrawalMessage: this.withdrawalMessage,
                });

                alert("탈퇴처리 되었습니다");

                this.$store.dispatch("logout").then(() => {
                    this.$router.push("/");
                });
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        postWithdraw({ withdrawalReason, withdrawalMessage }) {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.post("/api/v1/me/withraw", { withdrawalReason, withdrawalMessage });

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
    watch: {
        leave() {
            document.querySelector("html").scrollTop = 0;
        },
    },
};
</script>
