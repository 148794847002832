var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('mypage-layout', {
    attrs: {
      "title": "쿠폰 내역"
    }
  }, [_c('mypage-coupons-nav'), _c('page-section', {
    staticClass: "page-section--sm py-0 fill-height",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('v-tabs-items', {
          staticClass: "fill-height",
          attrs: {
            "value": _vm.tab
          }
        }, [_c('v-tab-item', {
          staticClass: "fill-height",
          attrs: {
            "value": null
          }
        }, [_c('mypage-coupons-list')], 1), _c('v-tab-item', {
          staticClass: "fill-height",
          attrs: {
            "value": "download"
          }
        }, [_c('mypage-coupons-down')], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }