<template>
    <client-page>
        <mypage-layout title="찜한상품">
            <!-- 상단조작버튼 -->
            <template v-if="items.length">
                <div class="pb-16px pb-md-20px">
                    <v-row align="center" class="row--xs">
                        <v-col cols="auto">
                            <select-all-btn v-model="selected" v-bind="{ items }">
                                <template #activator="{ active, toggle }">
                                    <v-checkbox v-bind="{ ...checkbox_primary, ...$attrs }" :value="active" label="전체선택" class="v-size--large" @click="toggle" />
                                    <!-- <v-btn text tile class="pa-0" @click="toggle"> <v-simple-checkbox :value="active" @click="toggle" /> 전체선택 </v-btn> -->
                                </template>
                            </select-all-btn>
                        </v-col>
                        <v-spacer />
                        <v-col cols="auto">
                            <remove-targets-btn v-model="loading" :filter="({ _id }) => selected.some((item) => item?._id == _id)" v-bind="{ items, remove }" v-on="{ search }">
                                <template #activator="{ attrs, on }">
                                    <v-btn v-bind="{ ...btn_tertiary, ...attrs }" v-on="on" small>
                                        <span><v-icon color="grey lighten-3" class="mr-6px">mdi-close</v-icon>선택삭제</span>
                                    </v-btn>
                                </template>
                            </remove-targets-btn>
                        </v-col>
                        <v-col cols="auto">
                            <remove-targets-btn v-model="loading" :filter="({ product }) => !product?.sells" v-bind="{ items, remove }" v-on="{ search }">
                                <template #activator="{ attrs, on }">
                                    <v-btn v-bind="{ ...btn_tertiary, ...attrs }" v-on="on" small>
                                        <span><v-icon color="grey lighten-3" class="mr-6px">mdi-close</v-icon>주문불가삭제</span>
                                    </v-btn>
                                </template>
                            </remove-targets-btn>
                        </v-col>
                    </v-row>
                </div>

                <!-- 찜한상품목록 -->
                <div class="border-t border-2 border-dark">
                    <like-product-item v-for="item in items" :key="item._id" v-model="selected" v-bind="{ item }" v-on="{ remove }" />
                </div>

                <!-- 페이지네이션 -->
                <div class="pagination-wrap">
                    <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                </div>
            </template>

            <template v-else>
                <div class="text-center">
                    <div class="mb-18px mb-md-24px">
                        <v-icon size="60px" color="primary">mdi-heart</v-icon>
                    </div>
                    <div class="tit tit--sm">찜한상품이 없습니다.</div>
                    <div class="txt txt--sm mt-8px">원하시는 상품을 담아주세요.</div>

                    <div class="btn-wrap btn-wrap--lg">
                        <v-row justify="center" class="row--sm">
                            <v-col cols="6" sm="auto">
                                <v-btn v-bind="{ ...btn_primary, ...$attrs }" to="/shop" class="v-size--xx-large w-100 min-w-sm-200px">쇼핑 계속하기</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
        </mypage-layout>
        <product-form-modal-cart ref="modal-cart" />
    </client-page>
</template>

<script>
import api from "@/api";
import { mapActions } from "vuex";
import { btn_primary, btn_tertiary, checkbox_primary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";

import SelectAllBtn from "@/components/client/dumb/select-all-btn.vue";
import LikeProductItem from "@/components/client/mypage/likes/like-product-item.vue";
import RemoveTargetsBtn from "@/components/client/dumb/remove-targets-btn.vue";
import ProductFormModalCart from "@/components/client/shop/products/form/product-form-modal-cart.vue";

export default {
    components: {
        ClientPage,
        MypageLayout,

        SelectAllBtn,
        LikeProductItem,
        RemoveTargetsBtn,
        ProductFormModalCart,
    },
    data() {
        return {
            btn_primary,
            btn_tertiary,
            checkbox_primary,

            likes: [],

            limit: 20,
            summary: { totalCount: 0 },

            selected: [],
            loading: false,
        };
    },
    computed: {
        items() {
            return [...this.likes].map((item, index) => ({ index: this.summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { query } = this.$route;
            return { ...query, withPopulate: true };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        ...mapActions(["getLikes"]),
        async init() {
            try {
                this.selected = [];
                this.search();
            } catch (error) {
                this.$handleError(error);
            }
        },

        async search() {
            this.loading = true;
            try {
                let { skip, limit, params } = this;
                var { summary, likes } = await api.v1.me.likes.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.likes = likes;
                await this.getLikes();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async remove(item) {
            try {
                await api.v1.me.likes.delete(item);
                this.likes = this.likes.filter(({ _id }) => _id != item?._id);
                this.summary.totalCount -= 1;
                if (!this.loading) this.search();
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
};
</script>

<style></style>
