<template>
    <client-page>
        <mypage-layout title="기타 문의 내역">
            <!-- S: 게시판 -->
            <v-data-table v-bind="{ items, headers, loading }" dense hide-default-footer disable-filtering disable-sort no-data-text="작성된 기타 문의가 없습니다" :expanded.sync="expanded" @click:row="clickRow" mobile-breakpoint="768" class="v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile v-data-table--tit v-data-table--cursor-pointer">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

                <template #[`item.state`]="{ item }">
                    <span v-if="item.reply" class="primary--text">답변완료</span>
                    <span v-else class="grey--text text--lighten-1">답변대기</span>
                </template>

                <template #expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="cursor-default pa-0">
                        <form-list-item-desc :value="item" />
                    </td>
                </template>
            </v-data-table>
            <!-- E: 게시판 -->

            <!-- S: 검색 -->
            <!-- <div class="mt-20px mt-40px">
                <inquire-list-search v-bind="{ loading }" />
            </div> -->
            <!-- E: 검색 -->

            <!-- S: 페이징 -->
            <div class="pagination-wrap pagination-wrap--sm">
                <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
            </div>
            <!-- E: 페이징 -->
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import { BOARD_CATEGORIES__JFL_GUIDES, CONCIERGE_SERVICE_CATEGORIES, FORM_CATEGORIES__JFL_CARES, FORM_CODES, USER_TYPES } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import InquireListSearch from "@/components/client/mypage/inquires/inquire-list-search.vue";
import FormListItemDesc from "@/components/client/mypage/forms/form-list-item-desc.vue";

const headers = [
    { text: "대분류", value: "code", width: 160, formatter: (key) => FORM_CODES[key]?.text || "기타" },
    { text: "소분류", value: "category", width: 160, formatter: (key) => [BOARD_CATEGORIES__JFL_GUIDES, CONCIERGE_SERVICE_CATEGORIES, FORM_CATEGORIES__JFL_CARES, USER_TYPES].find((CATEGORIES) => CATEGORIES[key])?.[key]?.text || key || "-" },
    { text: "제목", value: "subject", width: "auto", align: " ", class: "text-center" },
    { width: "16%", text: "작성일", value: "createdAt", formatter: (value) => value?.toDate?.() || value || "-" },
    // { width: "14%", text: "상태", value: "state" },
].map((item) => ({ ...item, align: item.align ?? "center" }));

export default {
    components: {
        ClientPage,
        PageSection,
        TitWrapLine,
        MypageLayout,
        InquireListSearch,
        FormListItemDesc,
    },
    data: () => ({
        forms: [],

        limit: 10,
        summary: { totalCount: 0 },

        headers,

        loading: false,
        expanded: [],
    }),
    computed: {
        items() {
            return [...this.forms];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            query.isMine = true;

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.forms = [];
            this.summary.totalCount = 0;
            this.loading = false;

            this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, forms } = await api.v1.forms.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.forms = forms;
            } finally {
                this.loading = false;
            }
        },

        clickRow(item, event) {
            if (event.isExpanded) {
                const index = this.expanded.findIndex((i) => i === item);
                this.expanded.splice(index, 1);
            } else {
                this.expanded.push(item);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep td.text-left {
    text-align: left !important;
}
</style>
